<template lang="pug">
  .information
    Breadcrumb(:current_path="{name: 'Information', title: 'Information'}")

    .container.information__container
      h1.title_2.information__title(v-html="$t('pages.information.title')")

      .information__content
        ul.information__list
          li.information__item(
            v-for="item in $t('pages.information.list')"
            :key="item"
          )
            p.information__item-title(v-html="item.title")
            p.information__item-text(v-html="item.text")
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'

export default {
  name: 'Information',
  components: { Breadcrumb }
}
</script>
